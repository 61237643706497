import { CheckboxStates } from 'appConstants';
import { parse, toSeconds } from 'iso8601-duration';

export const asTemplateString = (s: string) => {
  return s as unknown as TemplateStringsArray;
};

export const getDataFromPromiseResponse = <T>(dataResponse: PromiseSettledResult<T>) => {
  return dataResponse.status === 'fulfilled' ? dataResponse.value ?? [] : null;
};

export const findNextValueCheckbox = (currentCheckboxValue: CheckboxStates) => {
  if (currentCheckboxValue === CheckboxStates.Checked) {
    return CheckboxStates.Unchecked;
  } else if (currentCheckboxValue === CheckboxStates.Unchecked) {
    return CheckboxStates.Indeterminate;
  } else {
    return CheckboxStates.Checked;
  }
};

export const roundOneDigit = (element: number) => {
  return Math.floor(element * 10) / 10;
};

export const convertPercent = (value: number) => {
  return roundOneDigit(value * 100);
};

export const convertToHour = (value: string) => {
  return value ? roundOneDigit(toSeconds(parse(value)) / 360) : null;
};
