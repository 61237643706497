import React, { useState, useMemo, useEffect } from 'react';
import { head, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LatLngTuple } from 'leaflet';

import { ActiveTab, InfoDropdown, SearchBar, SensorsCluster, Tabs } from 'components/molecules';
import { EquipmentList, LeafletMap } from 'components/organisms';
import { LegendAnalytics } from 'components/atoms';

import { nbSmVOutOfRangeOption, networkAnalyticsOptions } from 'appConstants';
import { createStaticTabs, findItemBy } from 'helpers';
import { useTopology, useAppSelector, useSensors } from 'hooks';

type Props = {
  activeSecondarySubstation: SecondarySubstation | undefined;
  substationsTabList: SecondarySubstation[];
  hasAnalytics: boolean;
  mapCenter: LatLngTuple | undefined;
  addTab: (secondarySubId: string) => void;
  removeTabs: (secondarySubId: string[]) => void;
  resetActiveFeeder?: () => void;
  setMapCenter: (newCenter: LatLngTuple) => void;
};

const SubstationsTabs = ({
  hasAnalytics,
  substationsTabList,
  activeSecondarySubstation,
  mapCenter,
  addTab,
  removeTabs,
  resetActiveFeeder,
  setMapCenter,
}: Props) => {
  const { t } = useTranslation();
  const { topology } = useTopology();
  const { datasetId } = useAppSelector((state) => state.session.dataContext);
  const { secondarySubstations, getSecSubBySmartMeterName } = useSensors();
  const secSubTabs = createStaticTabs('secondary_substation_plural');

  const [currentAnalyticsOption, setCurrentAnalyticsOption] = useState(nbSmVOutOfRangeOption);
  const [secSubActiveTab, setSecSubActiveTab] = useState(head(secSubTabs)?.identifier);

  const filteredSecSub = useMemo(() => {
    const withTopology = secondarySubstations?.filter(
      (secSub: SecondarySubstation) => !isEmpty(topology[secSub.identifier])
    );
    const withTopologyAndLocation = withTopology?.filter(
      (secSub: SecondarySubstation) => secSub.location
    );
    return { withTopology, withTopologyAndLocation };
  }, [secondarySubstations, topology]);
  const secSubActiveTabIndex = useMemo(() => {
    return secSubTabs.findIndex(
      (s: { name: string; identifier: string }) => s?.identifier === secSubActiveTab
    );
  }, [secSubActiveTab, secSubTabs]);

  const getActiveIdentifiers = (list: SecondarySubstation[]) => {
    return list?.map((item) => item?.identifier);
  };
  const onEquipmentListChange = (
    equipment: SecondarySubstation | SmartMeter,
    isChecked: boolean
  ) => {
    !isChecked ? addTab(equipment.identifier) : removeTabs([equipment.identifier]);
  };

  const onSelectMapSecondarySubstation = (secSub: SecondarySubstation) => {
    if (secSub.identifier !== activeSecondarySubstation?.identifier) {
      onSelectSecondarySubstationTab(secSub.identifier);
    }
  };

  const onSelectSecondarySubstationTab = (identifier: string) => {
    if (resetActiveFeeder) resetActiveFeeder();
    addTab(identifier);
  };

  const onMapSearch = async (searchText: string) => {
    const secondarySubstation: SecondarySubstation | null =
      (secondarySubstations &&
        (findItemBy('name', secondarySubstations, searchText) as SecondarySubstation)) ??
      (await getSecSubBySmartMeterName(searchText, datasetId, topology));

    if (secondarySubstation) {
      onSelectSecondarySubstationTab(secondarySubstation.identifier);
    }
  };

  const onSelectAnalyticOption = (value: string) => {
    const selectedOption = networkAnalyticsOptions.find((option) => option.key === value);
    selectedOption && setCurrentAnalyticsOption(selectedOption);
  };

  useEffect(() => {
    if (activeSecondarySubstation && activeSecondarySubstation.location) {
      setMapCenter(activeSecondarySubstation.location.coordinates);
    }
  }, [activeSecondarySubstation]);

  useEffect(() => {
    if (!filteredSecSub.withTopologyAndLocation?.length) {
      setSecSubActiveTab('List');
    }
  }, [filteredSecSub.withTopologyAndLocation]);

  return (
    <>
      <Tabs
        tabClasses="tab-primary is-flex is-flex-direction-column"
        openTab={setSecSubActiveTab}
        activeTabIndex={secSubActiveTabIndex}
        tabArray={secSubTabs}
      />
      <ActiveTab activeTabIndex={secSubActiveTabIndex}>
        <div className="is-flex-column is-full-dim">
          {hasAnalytics && (
            <InfoDropdown
              dropdownOptions={networkAnalyticsOptions}
              selectValue={(value: string) => onSelectAnalyticOption(value)}
              initialValue={`${currentAnalyticsOption.key}`}
              label={t('sort_options.sort')}
              name={'sort-analysis'}
              initialinfoValue={currentAnalyticsOption.key}
            ></InfoDropdown>
          )}
          <div className="is-flex-grow-1">
            {mapCenter && (
              <LeafletMap
                center={mapCenter}
                hasSearchBar={true}
                onSearch={onMapSearch}
                mapSlot={
                  <>
                    <SensorsCluster
                      secondarySubstations={filteredSecSub.withTopologyAndLocation ?? []}
                      activeSecondarySubstation={
                        activeSecondarySubstation ? activeSecondarySubstation : null
                      }
                      selectSecondarySubstation={onSelectMapSecondarySubstation}
                      {...(hasAnalytics && { currentAnalyticsOption })}
                    />
                    <SearchBar isOnMap={true} onSend={onMapSearch} />
                    {hasAnalytics && (
                      <LegendAnalytics currentAnalyticsOption={currentAnalyticsOption} />
                    )}
                  </>
                }
              />
            )}
          </div>
        </div>
        <>
          {hasAnalytics && (
            <InfoDropdown
              dropdownOptions={networkAnalyticsOptions}
              selectValue={(value: string) => onSelectAnalyticOption(value)}
              initialValue={`${currentAnalyticsOption.key}`}
              label={t('sort_options.sort')}
              name={'sort-analysis'}
              initialinfoValue={currentAnalyticsOption.key}
            ></InfoDropdown>
          )}

          <EquipmentList
            equipments={filteredSecSub.withTopology ?? []}
            selectedIdentifiers={getActiveIdentifiers(substationsTabList)}
            activeEquipmentId={activeSecondarySubstation?.identifier}
            isCheckedChange={onEquipmentListChange}
            {...(hasAnalytics &&
              currentAnalyticsOption && {
                additionalColumn: {
                  text: currentAnalyticsOption.translationKey,
                  key: currentAnalyticsOption.key,
                },
              })}
          />
        </>
      </ActiveTab>
    </>
  );
};

export default SubstationsTabs;
