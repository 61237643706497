import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Label, Logo } from 'components/atoms';
import { Languages } from 'components/molecules';
import { LoginForm } from 'components/organisms';
import './login.scss';

/* Logo used in the login page in SVG format  */
import logo from 'assets/img/corporate/siconia.svg';

const Login = () => {
  const { t, i18n } = useTranslation();

  const isActive = (language: string) => {
    let classes = 'is-flex-centered login-language-block';
    classes +=
      language === i18n.resolvedLanguage
        ? ' has-background-primary has-text-weight-bold is-hovered has-text-white'
        : '';
    return classes;
  };

  return (
    <div className="is-flex-grow-1 is-flex login-page">
      <div className="is-flex-grow-1 is-flex-centered is-flex-direction-column">
        <div className="login-title is-family-primary">
          <Trans t={t} i18nKey="login.title" />
        </div>
        <div className="login-label">
          <Label />
        </div>
      </div>
      <div className="login-block is-flex is-flex-direction-column">
        <div className="login-logo-languages is-flex-centered is-justify-content-space-between">
          <div className="login-logo is-flex-centered">
            <Logo logo={logo} />
          </div>
          <div className="is-flex is-flex-direction-column is-family-primary is-uppercase">
            <Languages textClasses={isActive} languageCode={true} />
          </div>
        </div>
        <div className="login-form-placement">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
