import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { LeafletMap, SideBar } from 'components/organisms';
import { SearchBar, UseCaseActions, SensorsCluster, InfoDropdown } from 'components/molecules';
import { Icon, LegendAnalytics, Loader } from 'components/atoms';

import { findItemBy } from 'helpers';
import { networkAnalyticsOptions, nbSmVOutOfRangeOption } from 'appConstants';
import { useAbortController, useAppSelector, useTopology, useSensors } from 'hooks';

import './mapping.scss';

const Mapping = () => {
  const { t } = useTranslation();
  const { controller } = useAbortController();
  const { datasetId, useCasePermissions } = useAppSelector((state) => state.session.dataContext);
  const { topology } = useTopology();
  const {
    getSecondarySubstations,
    secondarySubstations,
    getSmartMetersBySecSubstation,
    currentSmartMeters,
    setCurrentSmartMeters,
    getSecSubBySmartMeterName,
    mapCenter,
    setMapCenter,
    isLoading,
  } = useSensors();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSecondarySubstation, setActiveSecondarySubstation] =
    useState<SecondarySubstation | null>(null);
  const [currentAnalyticsOption, setCurrentAnalyticsOption] = useState(nbSmVOutOfRangeOption);

  useEffect(() => {
    datasetId &&
      !secondarySubstations &&
      !isEmpty(topology) &&
      getSecondarySubstations(datasetId, controller.signal);
  }, [controller.signal, datasetId, getSecondarySubstations, secondarySubstations, topology]);

  const onSelectSecondarySubstation = (secondarySubstation: SecondarySubstation) => {
    setCurrentSmartMeters([]);
    getSmartMetersBySecSubstation(
      topology,
      secondarySubstation.identifier,
      datasetId,
      true,
      controller.signal
    );
    setActiveSecondarySubstation(secondarySubstation);
    if (secondarySubstation.location) {
      setMapCenter(secondarySubstation.location.coordinates);
    }
    setIsSidebarOpen(true);
  };

  const onSidebarClose = () => {
    setIsSidebarOpen(false);
    setActiveSecondarySubstation(null);
    setCurrentSmartMeters([]);
  };

  const onSelectAnalyticOption = (value: string) => {
    const selectedOption = networkAnalyticsOptions.find((option) => option.key === value);
    selectedOption && setCurrentAnalyticsOption(selectedOption);
  };

  const onMapSearch = async (searchText: string) => {
    const secondarySubstation: SecondarySubstation | null =
      (secondarySubstations &&
        (findItemBy('name', secondarySubstations, searchText) as SecondarySubstation)) ??
      (await getSecSubBySmartMeterName(searchText, datasetId, topology));

    if (secondarySubstation) {
      onSelectSecondarySubstation(secondarySubstation);
    }
  };

  const renderSideBar = {
    title: () => {
      return (
        <p className="is-flex is-align-items-start has-text-weight-bold pt-2">
          <Icon name="substation" classes="is-align-self-baseline is-flex-shrink-0" />
          <span className="sidebar-title ml-2 is-uppercase">
            {activeSecondarySubstation && activeSecondarySubstation.name}
          </span>
        </p>
      );
    },
    body: () => {
      return (
        <ul className="is-flex is-flex-wrap-wrap is-relative">
          {isLoading.smartMeters && (
            <li className="pt-2">
              <Loader />
            </li>
          )}
          {activeSecondarySubstation &&
            currentSmartMeters.length > 0 &&
            currentSmartMeters.map((smartMeter) => (
              <li
                className="counter-item is-flex is-align-items-center is-family-secondary"
                key={`sidebar-smartmeter-${smartMeter.identifier}`}
              >
                <Icon name="bolt" />
                <span className="ml-2 is-uppercase item-name">{smartMeter.name}</span>
              </li>
            ))}
          {!isLoading.smartMeters && activeSecondarySubstation && !currentSmartMeters.length && (
            <li className="pt-2 has-text-grey">{t('mapping.no_smartmeters')}</li>
          )}
        </ul>
      );
    },
  };

  return (
    <div
      className={`mapping-container is-relative is-flex-grow-1 is-family-primary ${
        isSidebarOpen && 'sidebar-open'
      }`}
    >
      {isLoading.secSubs && <Loader />}
      <div className="is-full-height is-flex is-justify-content-center is-align-items-center">
        {mapCenter && (
          <LeafletMap
            hasSearchBar={true}
            center={mapCenter}
            onSearch={onMapSearch}
            mapSlot={
              secondarySubstations &&
              secondarySubstations.length > 0 && (
                <>
                  <SensorsCluster
                    secondarySubstations={secondarySubstations}
                    activeSecondarySubstation={activeSecondarySubstation}
                    selectSecondarySubstation={onSelectSecondarySubstation}
                    smartMeters={currentSmartMeters}
                    topology={topology}
                    showPopup={true}
                    currentAnalyticsOption={currentAnalyticsOption}
                  />
                  <SearchBar isOnMap={true} onSend={onMapSearch} />

                  <LegendAnalytics currentAnalyticsOption={currentAnalyticsOption} />

                  <div className={'map-dropdown-container'}>
                    <InfoDropdown
                      dropdownOptions={networkAnalyticsOptions}
                      selectValue={(value: string) => onSelectAnalyticOption(value)}
                      initialValue={currentAnalyticsOption.key}
                      label={t('sort_options.sort')}
                      name={'sort-analysis'}
                      initialinfoValue={currentAnalyticsOption.key}
                    ></InfoDropdown>
                  </div>
                </>
              )
            }
          />
        )}
        <div className={`sidebar-section ${isSidebarOpen && 'is-open'}`}>
          <SideBar
            closeSidebar={onSidebarClose}
            titleSlot={renderSideBar.title()}
            bodySlot={renderSideBar.body()}
            footerSlot={
              <>
                {topology &&
                  activeSecondarySubstation &&
                  topology[activeSecondarySubstation.identifier] && (
                    <UseCaseActions
                      selectedSecSubstationId={activeSecondarySubstation.identifier}
                      activeLinks={{
                        analysis: useCasePermissions.includes('analysis'),
                        impact: useCasePermissions.includes('impact'),
                        rebalancing: useCasePermissions.includes('rebalancing'),
                      }}
                    />
                  )}
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Mapping;
