import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isEmpty, head } from 'lodash';

import { Icon, Loader } from 'components/atoms';
import { Tabs, ActiveTab, SensorsCluster } from 'components/molecules';
import { EquipmentList, LeafletMap, DataVisualizer, SubstationsTabs } from 'components/organisms';
import { SplitScreenTemplate, SplitColumnTemplate } from 'components/templates';
import {
  useAbortController,
  useAppSelector,
  useSensors,
  useTopology,
  useSubstationsTabList,
} from 'hooks';
import { createFeederTabs, asTemplateString, createStaticTabs } from 'helpers';
import './analysis.scss';

const Analysis = () => {
  const { t } = useTranslation();
  const { topology } = useTopology();
  const { controller } = useAbortController();
  const { datasetId } = useAppSelector((state) => state.session.dataContext);
  const {
    secondarySubstations,
    getSecondarySubstations,
    getSmartMetersBySecSubstation,
    currentSmartMeters,
    setCurrentSmartMeters,
    mapCenter,
    isLoading,
    currentFeeders,
    setMapCenter,
    setCurrentFeeders,
    smartMetersWithLocation,
  } = useSensors();
  const smartMetersTabs = useMemo(() => createStaticTabs('smart_meter_plural'), [t]);
  const location = useLocation();
  const [activeSecondarySubstation, setActiveSecondarySubstation] = useState<SecondarySubstation>();
  const { substationsTabList, addTab, removeTabs, setSubstationsTabList } = useSubstationsTabList({
    secondarySubstations: secondarySubstations ?? [],
    activeSecondarySubstation,
    setActiveSecondarySubstation,
  });
  const [activeFeeder, setActiveFeeder] = useState<SecondarySubstationFeeder | null>();
  const [smartMetersActiveTab, setSmartMetersActiveTab] = useState(
    head(smartMetersTabs)?.identifier
  );

  /* TODO add the data Filter when parametric analysis is possible 
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false); */

  const smartMetersActiveTabIndex = useMemo(() => {
    return smartMetersTabs.findIndex(
      (s: { name: string; identifier: string }) => s?.identifier === smartMetersActiveTab
    );
  }, [smartMetersActiveTab, smartMetersTabs]);

  const activeFeederIndex = () => {
    const foundIndex = currentFeeders?.findIndex((s) => s?.identifier === activeFeeder?.identifier);
    if (foundIndex != null && foundIndex >= 0) {
      return foundIndex + 1;
    }
    return 0;
  };

  const onSelectFeeder = (feederIdentifier: string) => {
    const feeder = currentFeeders?.find((feeder) => feeder.identifier === feederIdentifier);
    setActiveFeeder(activeSecondarySubstation && feeder ? feeder : null);
  };

  useEffect(() => {
    datasetId &&
      !secondarySubstations &&
      !isEmpty(topology) &&
      getSecondarySubstations(datasetId, controller.signal);
  }, [controller.signal, datasetId, getSecondarySubstations, secondarySubstations, topology]);

  useEffect(() => {
    if (location.state) {
      const secondarySubFromNav = secondarySubstations?.find(
        (secondarySub: SecondarySubstation) => secondarySub.identifier === location.state
      );
      if (secondarySubFromNav) {
        setActiveSecondarySubstation(secondarySubFromNav);
        setSubstationsTabList((previousTabs) =>
          !previousTabs.includes(secondarySubFromNav)
            ? [...previousTabs, secondarySubFromNav]
            : previousTabs
        );
      }
    }
  }, [location, secondarySubstations, setSubstationsTabList]);

  useEffect(() => {
    if (activeSecondarySubstation) {
      setCurrentFeeders(null);
      setActiveFeeder(null);
      setCurrentSmartMeters([]);
      getSmartMetersBySecSubstation(
        topology,
        activeSecondarySubstation.identifier,
        datasetId,
        false
      );
    }
  }, [
    activeSecondarySubstation,
    datasetId,
    getSmartMetersBySecSubstation,
    setCurrentFeeders,
    setCurrentSmartMeters,
    topology,
  ]);

  const onSelectSecondarySubstationTab = (identifier: string) => {
    setActiveFeeder(null);
    addTab(identifier);
  };
  const secSub = useMemo(() => {
    return activeSecondarySubstation ? [activeSecondarySubstation] : [];
  }, [activeSecondarySubstation]);

  return (
    <div className="is-family-primary is-body">
      <SplitScreenTemplate
        ratio={33}
        leftSlot={
          <SplitColumnTemplate
            upperRatio={55}
            middleRatio={45}
            upperSlot={
              secondarySubstations && (
                <SubstationsTabs
                  hasAnalytics={true}
                  activeSecondarySubstation={activeSecondarySubstation}
                  addTab={addTab}
                  substationsTabList={substationsTabList}
                  removeTabs={removeTabs}
                  mapCenter={mapCenter}
                  setMapCenter={(mapCenter) => setMapCenter(mapCenter)}
                  resetActiveFeeder={() => setActiveFeeder(null)}
                />
              )
            }
            middleSlot={
              <>
                <Tabs
                  openTab={setSmartMetersActiveTab}
                  activeTabIndex={smartMetersActiveTabIndex}
                  tabArray={smartMetersTabs}
                />
                <ActiveTab activeTabIndex={smartMetersActiveTabIndex}>
                  <>
                    {mapCenter && (
                      <LeafletMap
                        center={mapCenter}
                        mapSlot={
                          <>
                            <SensorsCluster
                              secondarySubstations={secSub}
                              activeSecondarySubstation={activeSecondarySubstation ?? null}
                              smartMeters={smartMetersWithLocation}
                            />
                          </>
                        }
                      />
                    )}
                  </>
                  {isLoading.smartMeters ? (
                    <Loader />
                  ) : (
                    <EquipmentList equipments={currentSmartMeters} selectedIdentifiers={[]} />
                  )}
                </ActiveTab>
              </>
            }
          />
        }
        rightSlot={
          <div className={'page-container'}>
            {isLoading.smartMeters && <Loader />}
            {/* TODO: add the date filter once parametric analysis is possible
          <div>{isDateFilterOpen && <DateFilter onCloseFilter={setIsDateFilterOpen} />}</div>
            <div className="calendar-button">
              {!isDateFilterOpen && (
                <ButtonIcon iconName="calendar" activateEvent={setIsDateFilterOpen}></ButtonIcon>
              )}
            </div> */}
            <Tabs
              isScrollable={false}
              leftIcon={
                <span className="icon is-small">
                  <Icon name="substation" />
                </span>
              }
              removeTabs={removeTabs}
              openTab={onSelectSecondarySubstationTab}
              activeTabIndex={substationsTabList?.findIndex(
                (s) => s?.identifier === activeSecondarySubstation?.identifier
              )}
              tabArray={substationsTabList.map((secondarySub) => ({
                name: secondarySub?.name,
                identifier: secondarySub?.identifier,
              }))}
            />
            {activeSecondarySubstation ? (
              <>
                <Tabs
                  tabClasses="tab-secondary has-background-white"
                  openTab={onSelectFeeder}
                  activeTabIndex={activeFeederIndex()}
                  tabArray={createFeederTabs(
                    currentFeeders ?? [],
                    activeSecondarySubstation?.identifier ?? ''
                  ).map((equipment: { feederName: string; feederId: string }) => ({
                    name: t(asTemplateString(equipment?.feederName)),
                    identifier: equipment?.feederId,
                  }))}
                />
                <DataVisualizer
                  activeSecondarySubstation={activeSecondarySubstation}
                  activeFeeder={activeFeeder}
                  currentFeeders={currentFeeders}
                  currentSmartmeters={currentSmartMeters}
                />
              </>
            ) : (
              <div className="no-substation-selection is-full-height">
                {t('analysis.no_substation_selected')}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};
export default Analysis;
